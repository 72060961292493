import { usePage } from '@inertiajs/vue3';
import Country from '../Models/Country';

export function useCountries() {
    const countries = usePage().props.shop.countries;

    return {
        countries: countries.map((country) => new Country(country)),
        getCountryNameFromCode: (code) =>
            countries.find((country) => country.id === code)?.name,
    };
}
